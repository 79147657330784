/*This file was generated by NativeWind. Do not edit!*/
import nativewind = require('nativewind');

const { NativeWindStyleSheet } = nativewind;

export = NativeWindStyleSheet.create({
  styles: {
    "*": {
      "borderTopWidth": 0,
      "borderRightWidth": 0,
      "borderBottomWidth": 0,
      "borderLeftWidth": 0,
      "borderStyle": "solid",
      "borderTopColor": "#e5e7eb",
      "borderRightColor": "#e5e7eb",
      "borderBottomColor": "#e5e7eb",
      "borderLeftColor": "#e5e7eb"
    },
    "": {
      "borderTopWidth": 0,
      "borderRightWidth": 0,
      "borderBottomWidth": 0,
      "borderLeftWidth": 0,
      "borderStyle": "solid",
      "borderTopColor": "#e5e7eb",
      "borderRightColor": "#e5e7eb",
      "borderBottomColor": "#e5e7eb",
      "borderLeftColor": "#e5e7eb"
    },
    "html": {
      "lineHeight": 1.5,
      "fontFamily": "ui-sans-serif"
    },
    ":host": {
      "lineHeight": 1.5,
      "fontFamily": "ui-sans-serif"
    },
    "body": {
      "backgroundColor": "#ffffff"
    },
    "hr": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "abbr:where([title])": {
      "textDecorationLine": "underline",
      "textDecorationColor": "black",
      "textDecorationStyle": "dotted"
    },
    "code": {
      "fontFamily": "ui-monospace",
      "fontSize": "1em"
    },
    "kbd": {
      "fontFamily": "ui-monospace",
      "fontSize": "1em"
    },
    "samp": {
      "fontFamily": "ui-monospace",
      "fontSize": "1em"
    },
    "pre": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "small": {
      "fontSize": "80%"
    },
    "sub": {
      "fontSize": "75%",
      "lineHeight": 0,
      "position": "relative"
    },
    "sup": {
      "fontSize": "75%",
      "lineHeight": 0,
      "position": "relative"
    },
    "button": {
      "backgroundColor": "transparent"
    },
    "input": {
      "opacity": 1,
      "color": "#9ca3af"
    },
    "optgroup": {
      "fontFamily": "inherit",
      "fontSize": "100%",
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0,
      "paddingTop": 0,
      "paddingRight": 0,
      "paddingBottom": 0,
      "paddingLeft": 0
    },
    "select": {
      "textTransform": "none"
    },
    "textarea": {
      "opacity": 1,
      "color": "#9ca3af"
    },
    "input:where([type='button'])": {
      "backgroundColor": "transparent"
    },
    "input:where([type='reset'])": {
      "backgroundColor": "transparent"
    },
    "input:where([type='submit'])": {
      "backgroundColor": "transparent"
    },
    ":-moz-ui-invalid": {
      "shadowOffset": {
        "width": 0,
        "height": 0
      },
      "shadowRadius": 0,
      "shadowColor": "black",
      "shadowOpacity": 1
    },
    "blockquote": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "dl": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "dd": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "h1": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "h2": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "h3": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "h4": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "h5": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "h6": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "figure": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "p": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0
    },
    "fieldset": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0,
      "paddingTop": 0,
      "paddingRight": 0,
      "paddingBottom": 0,
      "paddingLeft": 0
    },
    "legend": {
      "paddingTop": 0,
      "paddingRight": 0,
      "paddingBottom": 0,
      "paddingLeft": 0
    },
    "ol": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0,
      "paddingTop": 0,
      "paddingRight": 0,
      "paddingBottom": 0,
      "paddingLeft": 0
    },
    "ul": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0,
      "paddingTop": 0,
      "paddingRight": 0,
      "paddingBottom": 0,
      "paddingLeft": 0
    },
    "menu": {
      "marginTop": 0,
      "marginRight": 0,
      "marginBottom": 0,
      "marginLeft": 0,
      "paddingTop": 0,
      "paddingRight": 0,
      "paddingBottom": 0,
      "paddingLeft": 0
    },
    "dialog": {
      "paddingTop": 0,
      "paddingRight": 0,
      "paddingBottom": 0,
      "paddingLeft": 0
    },
    "img": {
      "maxWidth": "100%"
    },
    "video": {
      "maxWidth": "100%"
    },
    "[hidden]": {
      "display": "none"
    },
    "body@0": {
      "backgroundColor": "#000000"
    },
    "container": {
      "width": "100%"
    },
    "container@0": {
      "maxWidth": 640
    },
    "container@1": {
      "maxWidth": 768
    },
    "container@2": {
      "maxWidth": 1024
    },
    "container@3": {
      "maxWidth": 1280
    },
    "container@4": {
      "maxWidth": 1536
    },
    "absolute": {
      "position": "absolute"
    },
    "relative": {
      "position": "relative"
    },
    "flex": {
      "display": "flex"
    },
    "hidden": {
      "display": "none"
    },
    "bg-amber-100": {
      "backgroundColor": "rgba(254, 243, 199, 1)"
    },
    "bg-amber-200": {
      "backgroundColor": "rgba(253, 230, 138, 1)"
    },
    "bg-amber-300": {
      "backgroundColor": "rgba(252, 211, 77, 1)"
    },
    "bg-amber-400": {
      "backgroundColor": "rgba(251, 191, 36, 1)"
    },
    "bg-amber-50": {
      "backgroundColor": "rgba(255, 251, 235, 1)"
    },
    "bg-amber-500": {
      "backgroundColor": "rgba(245, 158, 11, 1)"
    },
    "bg-amber-600": {
      "backgroundColor": "rgba(217, 119, 6, 1)"
    },
    "bg-amber-700": {
      "backgroundColor": "rgba(180, 83, 9, 1)"
    },
    "bg-amber-800": {
      "backgroundColor": "rgba(146, 64, 14, 1)"
    },
    "bg-amber-900": {
      "backgroundColor": "rgba(120, 53, 15, 1)"
    },
    "bg-amber-950": {
      "backgroundColor": "rgba(69, 26, 3, 1)"
    },
    "bg-black": {
      "backgroundColor": "rgba(0, 0, 0, 1)"
    },
    "bg-blue-100": {
      "backgroundColor": "rgba(219, 234, 254, 1)"
    },
    "bg-blue-200": {
      "backgroundColor": "rgba(191, 219, 254, 1)"
    },
    "bg-blue-300": {
      "backgroundColor": "rgba(147, 197, 253, 1)"
    },
    "bg-blue-400": {
      "backgroundColor": "rgba(96, 165, 250, 1)"
    },
    "bg-blue-50": {
      "backgroundColor": "rgba(239, 246, 255, 1)"
    },
    "bg-blue-500": {
      "backgroundColor": "rgba(59, 130, 246, 1)"
    },
    "bg-blue-600": {
      "backgroundColor": "rgba(37, 99, 235, 1)"
    },
    "bg-blue-700": {
      "backgroundColor": "rgba(29, 78, 216, 1)"
    },
    "bg-blue-800": {
      "backgroundColor": "rgba(30, 64, 175, 1)"
    },
    "bg-blue-900": {
      "backgroundColor": "rgba(30, 58, 138, 1)"
    },
    "bg-blue-950": {
      "backgroundColor": "rgba(23, 37, 84, 1)"
    },
    "bg-blueGray-100": {
      "backgroundColor": "rgba(241, 245, 249, 1)"
    },
    "bg-blueGray-200": {
      "backgroundColor": "rgba(226, 232, 240, 1)"
    },
    "bg-blueGray-300": {
      "backgroundColor": "rgba(203, 213, 225, 1)"
    },
    "bg-blueGray-400": {
      "backgroundColor": "rgba(148, 163, 184, 1)"
    },
    "bg-blueGray-50": {
      "backgroundColor": "rgba(248, 250, 252, 1)"
    },
    "bg-blueGray-500": {
      "backgroundColor": "rgba(100, 116, 139, 1)"
    },
    "bg-blueGray-600": {
      "backgroundColor": "rgba(71, 85, 105, 1)"
    },
    "bg-blueGray-700": {
      "backgroundColor": "rgba(51, 65, 85, 1)"
    },
    "bg-blueGray-800": {
      "backgroundColor": "rgba(30, 41, 59, 1)"
    },
    "bg-blueGray-900": {
      "backgroundColor": "rgba(15, 23, 42, 1)"
    },
    "bg-blueGray-950": {
      "backgroundColor": "rgba(2, 6, 23, 1)"
    },
    "bg-coolGray-100": {
      "backgroundColor": "rgba(243, 244, 246, 1)"
    },
    "bg-coolGray-200": {
      "backgroundColor": "rgba(229, 231, 235, 1)"
    },
    "bg-coolGray-300": {
      "backgroundColor": "rgba(209, 213, 219, 1)"
    },
    "bg-coolGray-400": {
      "backgroundColor": "rgba(156, 163, 175, 1)"
    },
    "bg-coolGray-50": {
      "backgroundColor": "rgba(249, 250, 251, 1)"
    },
    "bg-coolGray-500": {
      "backgroundColor": "rgba(107, 114, 128, 1)"
    },
    "bg-coolGray-600": {
      "backgroundColor": "rgba(75, 85, 99, 1)"
    },
    "bg-coolGray-700": {
      "backgroundColor": "rgba(55, 65, 81, 1)"
    },
    "bg-coolGray-800": {
      "backgroundColor": "rgba(31, 41, 55, 1)"
    },
    "bg-coolGray-900": {
      "backgroundColor": "rgba(17, 24, 39, 1)"
    },
    "bg-coolGray-950": {
      "backgroundColor": "rgba(3, 7, 18, 1)"
    },
    "bg-cyan-100": {
      "backgroundColor": "rgba(207, 250, 254, 1)"
    },
    "bg-cyan-200": {
      "backgroundColor": "rgba(165, 243, 252, 1)"
    },
    "bg-cyan-300": {
      "backgroundColor": "rgba(103, 232, 249, 1)"
    },
    "bg-cyan-400": {
      "backgroundColor": "rgba(34, 211, 238, 1)"
    },
    "bg-cyan-50": {
      "backgroundColor": "rgba(236, 254, 255, 1)"
    },
    "bg-cyan-500": {
      "backgroundColor": "rgba(6, 182, 212, 1)"
    },
    "bg-cyan-600": {
      "backgroundColor": "rgba(8, 145, 178, 1)"
    },
    "bg-cyan-700": {
      "backgroundColor": "rgba(14, 116, 144, 1)"
    },
    "bg-cyan-800": {
      "backgroundColor": "rgba(21, 94, 117, 1)"
    },
    "bg-cyan-900": {
      "backgroundColor": "rgba(22, 78, 99, 1)"
    },
    "bg-cyan-950": {
      "backgroundColor": "rgba(8, 51, 68, 1)"
    },
    "bg-emerald-100": {
      "backgroundColor": "rgba(209, 250, 229, 1)"
    },
    "bg-emerald-200": {
      "backgroundColor": "rgba(167, 243, 208, 1)"
    },
    "bg-emerald-300": {
      "backgroundColor": "rgba(110, 231, 183, 1)"
    },
    "bg-emerald-400": {
      "backgroundColor": "rgba(52, 211, 153, 1)"
    },
    "bg-emerald-50": {
      "backgroundColor": "rgba(236, 253, 245, 1)"
    },
    "bg-emerald-500": {
      "backgroundColor": "rgba(16, 185, 129, 1)"
    },
    "bg-emerald-600": {
      "backgroundColor": "rgba(5, 150, 105, 1)"
    },
    "bg-emerald-700": {
      "backgroundColor": "rgba(4, 120, 87, 1)"
    },
    "bg-emerald-800": {
      "backgroundColor": "rgba(6, 95, 70, 1)"
    },
    "bg-emerald-900": {
      "backgroundColor": "rgba(6, 78, 59, 1)"
    },
    "bg-emerald-950": {
      "backgroundColor": "rgba(2, 44, 34, 1)"
    },
    "bg-fuchsia-100": {
      "backgroundColor": "rgba(250, 232, 255, 1)"
    },
    "bg-fuchsia-200": {
      "backgroundColor": "rgba(245, 208, 254, 1)"
    },
    "bg-fuchsia-300": {
      "backgroundColor": "rgba(240, 171, 252, 1)"
    },
    "bg-fuchsia-400": {
      "backgroundColor": "rgba(232, 121, 249, 1)"
    },
    "bg-fuchsia-50": {
      "backgroundColor": "rgba(253, 244, 255, 1)"
    },
    "bg-fuchsia-500": {
      "backgroundColor": "rgba(217, 70, 239, 1)"
    },
    "bg-fuchsia-600": {
      "backgroundColor": "rgba(192, 38, 211, 1)"
    },
    "bg-fuchsia-700": {
      "backgroundColor": "rgba(162, 28, 175, 1)"
    },
    "bg-fuchsia-800": {
      "backgroundColor": "rgba(134, 25, 143, 1)"
    },
    "bg-fuchsia-900": {
      "backgroundColor": "rgba(112, 26, 117, 1)"
    },
    "bg-fuchsia-950": {
      "backgroundColor": "rgba(74, 4, 78, 1)"
    },
    "bg-gray-100": {
      "backgroundColor": "rgba(243, 244, 246, 1)"
    },
    "bg-gray-200": {
      "backgroundColor": "rgba(229, 231, 235, 1)"
    },
    "bg-gray-300": {
      "backgroundColor": "rgba(209, 213, 219, 1)"
    },
    "bg-gray-400": {
      "backgroundColor": "rgba(156, 163, 175, 1)"
    },
    "bg-gray-50": {
      "backgroundColor": "rgba(249, 250, 251, 1)"
    },
    "bg-gray-500": {
      "backgroundColor": "rgba(107, 114, 128, 1)"
    },
    "bg-gray-600": {
      "backgroundColor": "rgba(75, 85, 99, 1)"
    },
    "bg-gray-700": {
      "backgroundColor": "rgba(55, 65, 81, 1)"
    },
    "bg-gray-800": {
      "backgroundColor": "rgba(31, 41, 55, 1)"
    },
    "bg-gray-900": {
      "backgroundColor": "rgba(17, 24, 39, 1)"
    },
    "bg-gray-950": {
      "backgroundColor": "rgba(3, 7, 18, 1)"
    },
    "bg-green-100": {
      "backgroundColor": "rgba(220, 252, 231, 1)"
    },
    "bg-green-200": {
      "backgroundColor": "rgba(187, 247, 208, 1)"
    },
    "bg-green-300": {
      "backgroundColor": "rgba(134, 239, 172, 1)"
    },
    "bg-green-400": {
      "backgroundColor": "rgba(74, 222, 128, 1)"
    },
    "bg-green-50": {
      "backgroundColor": "rgba(240, 253, 244, 1)"
    },
    "bg-green-500": {
      "backgroundColor": "rgba(34, 197, 94, 1)"
    },
    "bg-green-600": {
      "backgroundColor": "rgba(22, 163, 74, 1)"
    },
    "bg-green-700": {
      "backgroundColor": "rgba(21, 128, 61, 1)"
    },
    "bg-green-800": {
      "backgroundColor": "rgba(22, 101, 52, 1)"
    },
    "bg-green-900": {
      "backgroundColor": "rgba(20, 83, 45, 1)"
    },
    "bg-green-950": {
      "backgroundColor": "rgba(5, 46, 22, 1)"
    },
    "bg-indigo-100": {
      "backgroundColor": "rgba(224, 231, 255, 1)"
    },
    "bg-indigo-200": {
      "backgroundColor": "rgba(199, 210, 254, 1)"
    },
    "bg-indigo-300": {
      "backgroundColor": "rgba(165, 180, 252, 1)"
    },
    "bg-indigo-400": {
      "backgroundColor": "rgba(129, 140, 248, 1)"
    },
    "bg-indigo-50": {
      "backgroundColor": "rgba(238, 242, 255, 1)"
    },
    "bg-indigo-500": {
      "backgroundColor": "rgba(99, 102, 241, 1)"
    },
    "bg-indigo-600": {
      "backgroundColor": "rgba(79, 70, 229, 1)"
    },
    "bg-indigo-700": {
      "backgroundColor": "rgba(67, 56, 202, 1)"
    },
    "bg-indigo-800": {
      "backgroundColor": "rgba(55, 48, 163, 1)"
    },
    "bg-indigo-900": {
      "backgroundColor": "rgba(49, 46, 129, 1)"
    },
    "bg-indigo-950": {
      "backgroundColor": "rgba(30, 27, 75, 1)"
    },
    "bg-lightBlue-100": {
      "backgroundColor": "rgba(224, 242, 254, 1)"
    },
    "bg-lightBlue-200": {
      "backgroundColor": "rgba(186, 230, 253, 1)"
    },
    "bg-lightBlue-300": {
      "backgroundColor": "rgba(125, 211, 252, 1)"
    },
    "bg-lightBlue-400": {
      "backgroundColor": "rgba(56, 189, 248, 1)"
    },
    "bg-lightBlue-50": {
      "backgroundColor": "rgba(240, 249, 255, 1)"
    },
    "bg-lightBlue-500": {
      "backgroundColor": "rgba(14, 165, 233, 1)"
    },
    "bg-lightBlue-600": {
      "backgroundColor": "rgba(2, 132, 199, 1)"
    },
    "bg-lightBlue-700": {
      "backgroundColor": "rgba(3, 105, 161, 1)"
    },
    "bg-lightBlue-800": {
      "backgroundColor": "rgba(7, 89, 133, 1)"
    },
    "bg-lightBlue-900": {
      "backgroundColor": "rgba(12, 74, 110, 1)"
    },
    "bg-lightBlue-950": {
      "backgroundColor": "rgba(8, 47, 73, 1)"
    },
    "bg-lime-100": {
      "backgroundColor": "rgba(236, 252, 203, 1)"
    },
    "bg-lime-200": {
      "backgroundColor": "rgba(217, 249, 157, 1)"
    },
    "bg-lime-300": {
      "backgroundColor": "rgba(190, 242, 100, 1)"
    },
    "bg-lime-400": {
      "backgroundColor": "rgba(163, 230, 53, 1)"
    },
    "bg-lime-50": {
      "backgroundColor": "rgba(247, 254, 231, 1)"
    },
    "bg-lime-500": {
      "backgroundColor": "rgba(132, 204, 22, 1)"
    },
    "bg-lime-600": {
      "backgroundColor": "rgba(101, 163, 13, 1)"
    },
    "bg-lime-700": {
      "backgroundColor": "rgba(77, 124, 15, 1)"
    },
    "bg-lime-800": {
      "backgroundColor": "rgba(63, 98, 18, 1)"
    },
    "bg-lime-900": {
      "backgroundColor": "rgba(54, 83, 20, 1)"
    },
    "bg-lime-950": {
      "backgroundColor": "rgba(26, 46, 5, 1)"
    },
    "bg-neutral-100": {
      "backgroundColor": "rgba(245, 245, 245, 1)"
    },
    "bg-neutral-200": {
      "backgroundColor": "rgba(229, 229, 229, 1)"
    },
    "bg-neutral-300": {
      "backgroundColor": "rgba(212, 212, 212, 1)"
    },
    "bg-neutral-400": {
      "backgroundColor": "rgba(163, 163, 163, 1)"
    },
    "bg-neutral-50": {
      "backgroundColor": "rgba(250, 250, 250, 1)"
    },
    "bg-neutral-500": {
      "backgroundColor": "rgba(115, 115, 115, 1)"
    },
    "bg-neutral-600": {
      "backgroundColor": "rgba(82, 82, 82, 1)"
    },
    "bg-neutral-700": {
      "backgroundColor": "rgba(64, 64, 64, 1)"
    },
    "bg-neutral-800": {
      "backgroundColor": "rgba(38, 38, 38, 1)"
    },
    "bg-neutral-900": {
      "backgroundColor": "rgba(23, 23, 23, 1)"
    },
    "bg-neutral-950": {
      "backgroundColor": "rgba(10, 10, 10, 1)"
    },
    "bg-orange-100": {
      "backgroundColor": "rgba(255, 237, 213, 1)"
    },
    "bg-orange-200": {
      "backgroundColor": "rgba(254, 215, 170, 1)"
    },
    "bg-orange-300": {
      "backgroundColor": "rgba(253, 186, 116, 1)"
    },
    "bg-orange-400": {
      "backgroundColor": "rgba(251, 146, 60, 1)"
    },
    "bg-orange-50": {
      "backgroundColor": "rgba(255, 247, 237, 1)"
    },
    "bg-orange-500": {
      "backgroundColor": "rgba(249, 115, 22, 1)"
    },
    "bg-orange-600": {
      "backgroundColor": "rgba(234, 88, 12, 1)"
    },
    "bg-orange-700": {
      "backgroundColor": "rgba(194, 65, 12, 1)"
    },
    "bg-orange-800": {
      "backgroundColor": "rgba(154, 52, 18, 1)"
    },
    "bg-orange-900": {
      "backgroundColor": "rgba(124, 45, 18, 1)"
    },
    "bg-orange-950": {
      "backgroundColor": "rgba(67, 20, 7, 1)"
    },
    "bg-pink-100": {
      "backgroundColor": "rgba(252, 231, 243, 1)"
    },
    "bg-pink-200": {
      "backgroundColor": "rgba(251, 207, 232, 1)"
    },
    "bg-pink-300": {
      "backgroundColor": "rgba(249, 168, 212, 1)"
    },
    "bg-pink-400": {
      "backgroundColor": "rgba(244, 114, 182, 1)"
    },
    "bg-pink-50": {
      "backgroundColor": "rgba(253, 242, 248, 1)"
    },
    "bg-pink-500": {
      "backgroundColor": "rgba(236, 72, 153, 1)"
    },
    "bg-pink-600": {
      "backgroundColor": "rgba(219, 39, 119, 1)"
    },
    "bg-pink-700": {
      "backgroundColor": "rgba(190, 24, 93, 1)"
    },
    "bg-pink-800": {
      "backgroundColor": "rgba(157, 23, 77, 1)"
    },
    "bg-pink-900": {
      "backgroundColor": "rgba(131, 24, 67, 1)"
    },
    "bg-pink-950": {
      "backgroundColor": "rgba(80, 7, 36, 1)"
    },
    "bg-purple-100": {
      "backgroundColor": "rgba(243, 232, 255, 1)"
    },
    "bg-purple-200": {
      "backgroundColor": "rgba(233, 213, 255, 1)"
    },
    "bg-purple-300": {
      "backgroundColor": "rgba(216, 180, 254, 1)"
    },
    "bg-purple-400": {
      "backgroundColor": "rgba(192, 132, 252, 1)"
    },
    "bg-purple-50": {
      "backgroundColor": "rgba(250, 245, 255, 1)"
    },
    "bg-purple-500": {
      "backgroundColor": "rgba(168, 85, 247, 1)"
    },
    "bg-purple-600": {
      "backgroundColor": "rgba(147, 51, 234, 1)"
    },
    "bg-purple-700": {
      "backgroundColor": "rgba(126, 34, 206, 1)"
    },
    "bg-purple-800": {
      "backgroundColor": "rgba(107, 33, 168, 1)"
    },
    "bg-purple-900": {
      "backgroundColor": "rgba(88, 28, 135, 1)"
    },
    "bg-purple-950": {
      "backgroundColor": "rgba(59, 7, 100, 1)"
    },
    "bg-red-100": {
      "backgroundColor": "rgba(254, 226, 226, 1)"
    },
    "bg-red-200": {
      "backgroundColor": "rgba(254, 202, 202, 1)"
    },
    "bg-red-300": {
      "backgroundColor": "rgba(252, 165, 165, 1)"
    },
    "bg-red-400": {
      "backgroundColor": "rgba(248, 113, 113, 1)"
    },
    "bg-red-50": {
      "backgroundColor": "rgba(254, 242, 242, 1)"
    },
    "bg-red-500": {
      "backgroundColor": "rgba(239, 68, 68, 1)"
    },
    "bg-red-600": {
      "backgroundColor": "rgba(220, 38, 38, 1)"
    },
    "bg-red-700": {
      "backgroundColor": "rgba(185, 28, 28, 1)"
    },
    "bg-red-800": {
      "backgroundColor": "rgba(153, 27, 27, 1)"
    },
    "bg-red-900": {
      "backgroundColor": "rgba(127, 29, 29, 1)"
    },
    "bg-red-950": {
      "backgroundColor": "rgba(69, 10, 10, 1)"
    },
    "bg-rose-100": {
      "backgroundColor": "rgba(255, 228, 230, 1)"
    },
    "bg-rose-200": {
      "backgroundColor": "rgba(254, 205, 211, 1)"
    },
    "bg-rose-300": {
      "backgroundColor": "rgba(253, 164, 175, 1)"
    },
    "bg-rose-400": {
      "backgroundColor": "rgba(251, 113, 133, 1)"
    },
    "bg-rose-50": {
      "backgroundColor": "rgba(255, 241, 242, 1)"
    },
    "bg-rose-500": {
      "backgroundColor": "rgba(244, 63, 94, 1)"
    },
    "bg-rose-600": {
      "backgroundColor": "rgba(225, 29, 72, 1)"
    },
    "bg-rose-700": {
      "backgroundColor": "rgba(190, 18, 60, 1)"
    },
    "bg-rose-800": {
      "backgroundColor": "rgba(159, 18, 57, 1)"
    },
    "bg-rose-900": {
      "backgroundColor": "rgba(136, 19, 55, 1)"
    },
    "bg-rose-950": {
      "backgroundColor": "rgba(76, 5, 25, 1)"
    },
    "bg-sky-100": {
      "backgroundColor": "rgba(224, 242, 254, 1)"
    },
    "bg-sky-200": {
      "backgroundColor": "rgba(186, 230, 253, 1)"
    },
    "bg-sky-300": {
      "backgroundColor": "rgba(125, 211, 252, 1)"
    },
    "bg-sky-400": {
      "backgroundColor": "rgba(56, 189, 248, 1)"
    },
    "bg-sky-50": {
      "backgroundColor": "rgba(240, 249, 255, 1)"
    },
    "bg-sky-500": {
      "backgroundColor": "rgba(14, 165, 233, 1)"
    },
    "bg-sky-600": {
      "backgroundColor": "rgba(2, 132, 199, 1)"
    },
    "bg-sky-700": {
      "backgroundColor": "rgba(3, 105, 161, 1)"
    },
    "bg-sky-800": {
      "backgroundColor": "rgba(7, 89, 133, 1)"
    },
    "bg-sky-900": {
      "backgroundColor": "rgba(12, 74, 110, 1)"
    },
    "bg-sky-950": {
      "backgroundColor": "rgba(8, 47, 73, 1)"
    },
    "bg-slate-100": {
      "backgroundColor": "rgba(241, 245, 249, 1)"
    },
    "bg-slate-200": {
      "backgroundColor": "rgba(226, 232, 240, 1)"
    },
    "bg-slate-300": {
      "backgroundColor": "rgba(203, 213, 225, 1)"
    },
    "bg-slate-400": {
      "backgroundColor": "rgba(148, 163, 184, 1)"
    },
    "bg-slate-50": {
      "backgroundColor": "rgba(248, 250, 252, 1)"
    },
    "bg-slate-500": {
      "backgroundColor": "rgba(100, 116, 139, 1)"
    },
    "bg-slate-600": {
      "backgroundColor": "rgba(71, 85, 105, 1)"
    },
    "bg-slate-700": {
      "backgroundColor": "rgba(51, 65, 85, 1)"
    },
    "bg-slate-800": {
      "backgroundColor": "rgba(30, 41, 59, 1)"
    },
    "bg-slate-900": {
      "backgroundColor": "rgba(15, 23, 42, 1)"
    },
    "bg-slate-950": {
      "backgroundColor": "rgba(2, 6, 23, 1)"
    },
    "bg-stone-100": {
      "backgroundColor": "rgba(245, 245, 244, 1)"
    },
    "bg-stone-200": {
      "backgroundColor": "rgba(231, 229, 228, 1)"
    },
    "bg-stone-300": {
      "backgroundColor": "rgba(214, 211, 209, 1)"
    },
    "bg-stone-400": {
      "backgroundColor": "rgba(168, 162, 158, 1)"
    },
    "bg-stone-50": {
      "backgroundColor": "rgba(250, 250, 249, 1)"
    },
    "bg-stone-500": {
      "backgroundColor": "rgba(120, 113, 108, 1)"
    },
    "bg-stone-600": {
      "backgroundColor": "rgba(87, 83, 78, 1)"
    },
    "bg-stone-700": {
      "backgroundColor": "rgba(68, 64, 60, 1)"
    },
    "bg-stone-800": {
      "backgroundColor": "rgba(41, 37, 36, 1)"
    },
    "bg-stone-900": {
      "backgroundColor": "rgba(28, 25, 23, 1)"
    },
    "bg-stone-950": {
      "backgroundColor": "rgba(12, 10, 9, 1)"
    },
    "bg-teal-100": {
      "backgroundColor": "rgba(204, 251, 241, 1)"
    },
    "bg-teal-200": {
      "backgroundColor": "rgba(153, 246, 228, 1)"
    },
    "bg-teal-300": {
      "backgroundColor": "rgba(94, 234, 212, 1)"
    },
    "bg-teal-400": {
      "backgroundColor": "rgba(45, 212, 191, 1)"
    },
    "bg-teal-50": {
      "backgroundColor": "rgba(240, 253, 250, 1)"
    },
    "bg-teal-500": {
      "backgroundColor": "rgba(20, 184, 166, 1)"
    },
    "bg-teal-600": {
      "backgroundColor": "rgba(13, 148, 136, 1)"
    },
    "bg-teal-700": {
      "backgroundColor": "rgba(15, 118, 110, 1)"
    },
    "bg-teal-800": {
      "backgroundColor": "rgba(17, 94, 89, 1)"
    },
    "bg-teal-900": {
      "backgroundColor": "rgba(19, 78, 74, 1)"
    },
    "bg-teal-950": {
      "backgroundColor": "rgba(4, 47, 46, 1)"
    },
    "bg-transparent": {
      "backgroundColor": "transparent"
    },
    "bg-trueGray-100": {
      "backgroundColor": "rgba(245, 245, 245, 1)"
    },
    "bg-trueGray-200": {
      "backgroundColor": "rgba(229, 229, 229, 1)"
    },
    "bg-trueGray-300": {
      "backgroundColor": "rgba(212, 212, 212, 1)"
    },
    "bg-trueGray-400": {
      "backgroundColor": "rgba(163, 163, 163, 1)"
    },
    "bg-trueGray-50": {
      "backgroundColor": "rgba(250, 250, 250, 1)"
    },
    "bg-trueGray-500": {
      "backgroundColor": "rgba(115, 115, 115, 1)"
    },
    "bg-trueGray-600": {
      "backgroundColor": "rgba(82, 82, 82, 1)"
    },
    "bg-trueGray-700": {
      "backgroundColor": "rgba(64, 64, 64, 1)"
    },
    "bg-trueGray-800": {
      "backgroundColor": "rgba(38, 38, 38, 1)"
    },
    "bg-trueGray-900": {
      "backgroundColor": "rgba(23, 23, 23, 1)"
    },
    "bg-trueGray-950": {
      "backgroundColor": "rgba(10, 10, 10, 1)"
    },
    "bg-violet-100": {
      "backgroundColor": "rgba(237, 233, 254, 1)"
    },
    "bg-violet-200": {
      "backgroundColor": "rgba(221, 214, 254, 1)"
    },
    "bg-violet-300": {
      "backgroundColor": "rgba(196, 181, 253, 1)"
    },
    "bg-violet-400": {
      "backgroundColor": "rgba(167, 139, 250, 1)"
    },
    "bg-violet-50": {
      "backgroundColor": "rgba(245, 243, 255, 1)"
    },
    "bg-violet-500": {
      "backgroundColor": "rgba(139, 92, 246, 1)"
    },
    "bg-violet-600": {
      "backgroundColor": "rgba(124, 58, 237, 1)"
    },
    "bg-violet-700": {
      "backgroundColor": "rgba(109, 40, 217, 1)"
    },
    "bg-violet-800": {
      "backgroundColor": "rgba(91, 33, 182, 1)"
    },
    "bg-violet-900": {
      "backgroundColor": "rgba(76, 29, 149, 1)"
    },
    "bg-violet-950": {
      "backgroundColor": "rgba(46, 16, 101, 1)"
    },
    "bg-warmGray-100": {
      "backgroundColor": "rgba(245, 245, 244, 1)"
    },
    "bg-warmGray-200": {
      "backgroundColor": "rgba(231, 229, 228, 1)"
    },
    "bg-warmGray-300": {
      "backgroundColor": "rgba(214, 211, 209, 1)"
    },
    "bg-warmGray-400": {
      "backgroundColor": "rgba(168, 162, 158, 1)"
    },
    "bg-warmGray-50": {
      "backgroundColor": "rgba(250, 250, 249, 1)"
    },
    "bg-warmGray-500": {
      "backgroundColor": "rgba(120, 113, 108, 1)"
    },
    "bg-warmGray-600": {
      "backgroundColor": "rgba(87, 83, 78, 1)"
    },
    "bg-warmGray-700": {
      "backgroundColor": "rgba(68, 64, 60, 1)"
    },
    "bg-warmGray-800": {
      "backgroundColor": "rgba(41, 37, 36, 1)"
    },
    "bg-warmGray-900": {
      "backgroundColor": "rgba(28, 25, 23, 1)"
    },
    "bg-warmGray-950": {
      "backgroundColor": "rgba(12, 10, 9, 1)"
    },
    "bg-white": {
      "backgroundColor": "rgba(255, 255, 255, 1)"
    },
    "bg-yellow-100": {
      "backgroundColor": "rgba(254, 249, 195, 1)"
    },
    "bg-yellow-200": {
      "backgroundColor": "rgba(254, 240, 138, 1)"
    },
    "bg-yellow-300": {
      "backgroundColor": "rgba(253, 224, 71, 1)"
    },
    "bg-yellow-400": {
      "backgroundColor": "rgba(250, 204, 21, 1)"
    },
    "bg-yellow-50": {
      "backgroundColor": "rgba(254, 252, 232, 1)"
    },
    "bg-yellow-500": {
      "backgroundColor": "rgba(234, 179, 8, 1)"
    },
    "bg-yellow-600": {
      "backgroundColor": "rgba(202, 138, 4, 1)"
    },
    "bg-yellow-700": {
      "backgroundColor": "rgba(161, 98, 7, 1)"
    },
    "bg-yellow-800": {
      "backgroundColor": "rgba(133, 77, 14, 1)"
    },
    "bg-yellow-900": {
      "backgroundColor": "rgba(113, 63, 18, 1)"
    },
    "bg-yellow-950": {
      "backgroundColor": "rgba(66, 32, 6, 1)"
    },
    "bg-zinc-100": {
      "backgroundColor": "rgba(244, 244, 245, 1)"
    },
    "bg-zinc-200": {
      "backgroundColor": "rgba(228, 228, 231, 1)"
    },
    "bg-zinc-300": {
      "backgroundColor": "rgba(212, 212, 216, 1)"
    },
    "bg-zinc-400": {
      "backgroundColor": "rgba(161, 161, 170, 1)"
    },
    "bg-zinc-50": {
      "backgroundColor": "rgba(250, 250, 250, 1)"
    },
    "bg-zinc-500": {
      "backgroundColor": "rgba(113, 113, 122, 1)"
    },
    "bg-zinc-600": {
      "backgroundColor": "rgba(82, 82, 91, 1)"
    },
    "bg-zinc-700": {
      "backgroundColor": "rgba(63, 63, 70, 1)"
    },
    "bg-zinc-800": {
      "backgroundColor": "rgba(39, 39, 42, 1)"
    },
    "bg-zinc-900": {
      "backgroundColor": "rgba(24, 24, 27, 1)"
    },
    "bg-zinc-950": {
      "backgroundColor": "rgba(9, 9, 11, 1)"
    },
    "underline": {
      "textDecorationLine": "underline"
    },
    "color-amber-100": {
      "color": "#fef3c7"
    },
    "color-amber-200": {
      "color": "#fde68a"
    },
    "color-amber-300": {
      "color": "#fcd34d"
    },
    "color-amber-400": {
      "color": "#fbbf24"
    },
    "color-amber-50": {
      "color": "#fffbeb"
    },
    "color-amber-500": {
      "color": "#f59e0b"
    },
    "color-amber-600": {
      "color": "#d97706"
    },
    "color-amber-700": {
      "color": "#b45309"
    },
    "color-amber-800": {
      "color": "#92400e"
    },
    "color-amber-900": {
      "color": "#78350f"
    },
    "color-amber-950": {
      "color": "#451a03"
    },
    "color-black": {
      "color": "#000"
    },
    "color-blue-100": {
      "color": "#dbeafe"
    },
    "color-blue-200": {
      "color": "#bfdbfe"
    },
    "color-blue-300": {
      "color": "#93c5fd"
    },
    "color-blue-400": {
      "color": "#60a5fa"
    },
    "color-blue-50": {
      "color": "#eff6ff"
    },
    "color-blue-500": {
      "color": "#3b82f6"
    },
    "color-blue-600": {
      "color": "#2563eb"
    },
    "color-blue-700": {
      "color": "#1d4ed8"
    },
    "color-blue-800": {
      "color": "#1e40af"
    },
    "color-blue-900": {
      "color": "#1e3a8a"
    },
    "color-blue-950": {
      "color": "#172554"
    },
    "color-blueGray-100": {
      "color": "#f1f5f9"
    },
    "color-blueGray-200": {
      "color": "#e2e8f0"
    },
    "color-blueGray-300": {
      "color": "#cbd5e1"
    },
    "color-blueGray-400": {
      "color": "#94a3b8"
    },
    "color-blueGray-50": {
      "color": "#f8fafc"
    },
    "color-blueGray-500": {
      "color": "#64748b"
    },
    "color-blueGray-600": {
      "color": "#475569"
    },
    "color-blueGray-700": {
      "color": "#334155"
    },
    "color-blueGray-800": {
      "color": "#1e293b"
    },
    "color-blueGray-900": {
      "color": "#0f172a"
    },
    "color-blueGray-950": {
      "color": "#020617"
    },
    "color-coolGray-100": {
      "color": "#f3f4f6"
    },
    "color-coolGray-200": {
      "color": "#e5e7eb"
    },
    "color-coolGray-300": {
      "color": "#d1d5db"
    },
    "color-coolGray-400": {
      "color": "#9ca3af"
    },
    "color-coolGray-50": {
      "color": "#f9fafb"
    },
    "color-coolGray-500": {
      "color": "#6b7280"
    },
    "color-coolGray-600": {
      "color": "#4b5563"
    },
    "color-coolGray-700": {
      "color": "#374151"
    },
    "color-coolGray-800": {
      "color": "#1f2937"
    },
    "color-coolGray-900": {
      "color": "#111827"
    },
    "color-coolGray-950": {
      "color": "#030712"
    },
    "color-cyan-100": {
      "color": "#cffafe"
    },
    "color-cyan-200": {
      "color": "#a5f3fc"
    },
    "color-cyan-300": {
      "color": "#67e8f9"
    },
    "color-cyan-400": {
      "color": "#22d3ee"
    },
    "color-cyan-50": {
      "color": "#ecfeff"
    },
    "color-cyan-500": {
      "color": "#06b6d4"
    },
    "color-cyan-600": {
      "color": "#0891b2"
    },
    "color-cyan-700": {
      "color": "#0e7490"
    },
    "color-cyan-800": {
      "color": "#155e75"
    },
    "color-cyan-900": {
      "color": "#164e63"
    },
    "color-cyan-950": {
      "color": "#083344"
    },
    "color-emerald-100": {
      "color": "#d1fae5"
    },
    "color-emerald-200": {
      "color": "#a7f3d0"
    },
    "color-emerald-300": {
      "color": "#6ee7b7"
    },
    "color-emerald-400": {
      "color": "#34d399"
    },
    "color-emerald-50": {
      "color": "#ecfdf5"
    },
    "color-emerald-500": {
      "color": "#10b981"
    },
    "color-emerald-600": {
      "color": "#059669"
    },
    "color-emerald-700": {
      "color": "#047857"
    },
    "color-emerald-800": {
      "color": "#065f46"
    },
    "color-emerald-900": {
      "color": "#064e3b"
    },
    "color-emerald-950": {
      "color": "#022c22"
    },
    "color-fuchsia-100": {
      "color": "#fae8ff"
    },
    "color-fuchsia-200": {
      "color": "#f5d0fe"
    },
    "color-fuchsia-300": {
      "color": "#f0abfc"
    },
    "color-fuchsia-400": {
      "color": "#e879f9"
    },
    "color-fuchsia-50": {
      "color": "#fdf4ff"
    },
    "color-fuchsia-500": {
      "color": "#d946ef"
    },
    "color-fuchsia-600": {
      "color": "#c026d3"
    },
    "color-fuchsia-700": {
      "color": "#a21caf"
    },
    "color-fuchsia-800": {
      "color": "#86198f"
    },
    "color-fuchsia-900": {
      "color": "#701a75"
    },
    "color-fuchsia-950": {
      "color": "#4a044e"
    },
    "color-gray-100": {
      "color": "#f3f4f6"
    },
    "color-gray-200": {
      "color": "#e5e7eb"
    },
    "color-gray-300": {
      "color": "#d1d5db"
    },
    "color-gray-400": {
      "color": "#9ca3af"
    },
    "color-gray-50": {
      "color": "#f9fafb"
    },
    "color-gray-500": {
      "color": "#6b7280"
    },
    "color-gray-600": {
      "color": "#4b5563"
    },
    "color-gray-700": {
      "color": "#374151"
    },
    "color-gray-800": {
      "color": "#1f2937"
    },
    "color-gray-900": {
      "color": "#111827"
    },
    "color-gray-950": {
      "color": "#030712"
    },
    "color-green-100": {
      "color": "#dcfce7"
    },
    "color-green-200": {
      "color": "#bbf7d0"
    },
    "color-green-300": {
      "color": "#86efac"
    },
    "color-green-400": {
      "color": "#4ade80"
    },
    "color-green-50": {
      "color": "#f0fdf4"
    },
    "color-green-500": {
      "color": "#22c55e"
    },
    "color-green-600": {
      "color": "#16a34a"
    },
    "color-green-700": {
      "color": "#15803d"
    },
    "color-green-800": {
      "color": "#166534"
    },
    "color-green-900": {
      "color": "#14532d"
    },
    "color-green-950": {
      "color": "#052e16"
    },
    "color-indigo-100": {
      "color": "#e0e7ff"
    },
    "color-indigo-200": {
      "color": "#c7d2fe"
    },
    "color-indigo-300": {
      "color": "#a5b4fc"
    },
    "color-indigo-400": {
      "color": "#818cf8"
    },
    "color-indigo-50": {
      "color": "#eef2ff"
    },
    "color-indigo-500": {
      "color": "#6366f1"
    },
    "color-indigo-600": {
      "color": "#4f46e5"
    },
    "color-indigo-700": {
      "color": "#4338ca"
    },
    "color-indigo-800": {
      "color": "#3730a3"
    },
    "color-indigo-900": {
      "color": "#312e81"
    },
    "color-indigo-950": {
      "color": "#1e1b4b"
    },
    "color-lightBlue-100": {
      "color": "#e0f2fe"
    },
    "color-lightBlue-200": {
      "color": "#bae6fd"
    },
    "color-lightBlue-300": {
      "color": "#7dd3fc"
    },
    "color-lightBlue-400": {
      "color": "#38bdf8"
    },
    "color-lightBlue-50": {
      "color": "#f0f9ff"
    },
    "color-lightBlue-500": {
      "color": "#0ea5e9"
    },
    "color-lightBlue-600": {
      "color": "#0284c7"
    },
    "color-lightBlue-700": {
      "color": "#0369a1"
    },
    "color-lightBlue-800": {
      "color": "#075985"
    },
    "color-lightBlue-900": {
      "color": "#0c4a6e"
    },
    "color-lightBlue-950": {
      "color": "#082f49"
    },
    "color-lime-100": {
      "color": "#ecfccb"
    },
    "color-lime-200": {
      "color": "#d9f99d"
    },
    "color-lime-300": {
      "color": "#bef264"
    },
    "color-lime-400": {
      "color": "#a3e635"
    },
    "color-lime-50": {
      "color": "#f7fee7"
    },
    "color-lime-500": {
      "color": "#84cc16"
    },
    "color-lime-600": {
      "color": "#65a30d"
    },
    "color-lime-700": {
      "color": "#4d7c0f"
    },
    "color-lime-800": {
      "color": "#3f6212"
    },
    "color-lime-900": {
      "color": "#365314"
    },
    "color-lime-950": {
      "color": "#1a2e05"
    },
    "color-neutral-100": {
      "color": "#f5f5f5"
    },
    "color-neutral-200": {
      "color": "#e5e5e5"
    },
    "color-neutral-300": {
      "color": "#d4d4d4"
    },
    "color-neutral-400": {
      "color": "#a3a3a3"
    },
    "color-neutral-50": {
      "color": "#fafafa"
    },
    "color-neutral-500": {
      "color": "#737373"
    },
    "color-neutral-600": {
      "color": "#525252"
    },
    "color-neutral-700": {
      "color": "#404040"
    },
    "color-neutral-800": {
      "color": "#262626"
    },
    "color-neutral-900": {
      "color": "#171717"
    },
    "color-neutral-950": {
      "color": "#0a0a0a"
    },
    "color-orange-100": {
      "color": "#ffedd5"
    },
    "color-orange-200": {
      "color": "#fed7aa"
    },
    "color-orange-300": {
      "color": "#fdba74"
    },
    "color-orange-400": {
      "color": "#fb923c"
    },
    "color-orange-50": {
      "color": "#fff7ed"
    },
    "color-orange-500": {
      "color": "#f97316"
    },
    "color-orange-600": {
      "color": "#ea580c"
    },
    "color-orange-700": {
      "color": "#c2410c"
    },
    "color-orange-800": {
      "color": "#9a3412"
    },
    "color-orange-900": {
      "color": "#7c2d12"
    },
    "color-orange-950": {
      "color": "#431407"
    },
    "color-pink-100": {
      "color": "#fce7f3"
    },
    "color-pink-200": {
      "color": "#fbcfe8"
    },
    "color-pink-300": {
      "color": "#f9a8d4"
    },
    "color-pink-400": {
      "color": "#f472b6"
    },
    "color-pink-50": {
      "color": "#fdf2f8"
    },
    "color-pink-500": {
      "color": "#ec4899"
    },
    "color-pink-600": {
      "color": "#db2777"
    },
    "color-pink-700": {
      "color": "#be185d"
    },
    "color-pink-800": {
      "color": "#9d174d"
    },
    "color-pink-900": {
      "color": "#831843"
    },
    "color-pink-950": {
      "color": "#500724"
    },
    "color-purple-100": {
      "color": "#f3e8ff"
    },
    "color-purple-200": {
      "color": "#e9d5ff"
    },
    "color-purple-300": {
      "color": "#d8b4fe"
    },
    "color-purple-400": {
      "color": "#c084fc"
    },
    "color-purple-50": {
      "color": "#faf5ff"
    },
    "color-purple-500": {
      "color": "#a855f7"
    },
    "color-purple-600": {
      "color": "#9333ea"
    },
    "color-purple-700": {
      "color": "#7e22ce"
    },
    "color-purple-800": {
      "color": "#6b21a8"
    },
    "color-purple-900": {
      "color": "#581c87"
    },
    "color-purple-950": {
      "color": "#3b0764"
    },
    "color-red-100": {
      "color": "#fee2e2"
    },
    "color-red-200": {
      "color": "#fecaca"
    },
    "color-red-300": {
      "color": "#fca5a5"
    },
    "color-red-400": {
      "color": "#f87171"
    },
    "color-red-50": {
      "color": "#fef2f2"
    },
    "color-red-500": {
      "color": "#ef4444"
    },
    "color-red-600": {
      "color": "#dc2626"
    },
    "color-red-700": {
      "color": "#b91c1c"
    },
    "color-red-800": {
      "color": "#991b1b"
    },
    "color-red-900": {
      "color": "#7f1d1d"
    },
    "color-red-950": {
      "color": "#450a0a"
    },
    "color-rose-100": {
      "color": "#ffe4e6"
    },
    "color-rose-200": {
      "color": "#fecdd3"
    },
    "color-rose-300": {
      "color": "#fda4af"
    },
    "color-rose-400": {
      "color": "#fb7185"
    },
    "color-rose-50": {
      "color": "#fff1f2"
    },
    "color-rose-500": {
      "color": "#f43f5e"
    },
    "color-rose-600": {
      "color": "#e11d48"
    },
    "color-rose-700": {
      "color": "#be123c"
    },
    "color-rose-800": {
      "color": "#9f1239"
    },
    "color-rose-900": {
      "color": "#881337"
    },
    "color-rose-950": {
      "color": "#4c0519"
    },
    "color-sky-100": {
      "color": "#e0f2fe"
    },
    "color-sky-200": {
      "color": "#bae6fd"
    },
    "color-sky-300": {
      "color": "#7dd3fc"
    },
    "color-sky-400": {
      "color": "#38bdf8"
    },
    "color-sky-50": {
      "color": "#f0f9ff"
    },
    "color-sky-500": {
      "color": "#0ea5e9"
    },
    "color-sky-600": {
      "color": "#0284c7"
    },
    "color-sky-700": {
      "color": "#0369a1"
    },
    "color-sky-800": {
      "color": "#075985"
    },
    "color-sky-900": {
      "color": "#0c4a6e"
    },
    "color-sky-950": {
      "color": "#082f49"
    },
    "color-slate-100": {
      "color": "#f1f5f9"
    },
    "color-slate-200": {
      "color": "#e2e8f0"
    },
    "color-slate-300": {
      "color": "#cbd5e1"
    },
    "color-slate-400": {
      "color": "#94a3b8"
    },
    "color-slate-50": {
      "color": "#f8fafc"
    },
    "color-slate-500": {
      "color": "#64748b"
    },
    "color-slate-600": {
      "color": "#475569"
    },
    "color-slate-700": {
      "color": "#334155"
    },
    "color-slate-800": {
      "color": "#1e293b"
    },
    "color-slate-900": {
      "color": "#0f172a"
    },
    "color-slate-950": {
      "color": "#020617"
    },
    "color-stone-100": {
      "color": "#f5f5f4"
    },
    "color-stone-200": {
      "color": "#e7e5e4"
    },
    "color-stone-300": {
      "color": "#d6d3d1"
    },
    "color-stone-400": {
      "color": "#a8a29e"
    },
    "color-stone-50": {
      "color": "#fafaf9"
    },
    "color-stone-500": {
      "color": "#78716c"
    },
    "color-stone-600": {
      "color": "#57534e"
    },
    "color-stone-700": {
      "color": "#44403c"
    },
    "color-stone-800": {
      "color": "#292524"
    },
    "color-stone-900": {
      "color": "#1c1917"
    },
    "color-stone-950": {
      "color": "#0c0a09"
    },
    "color-teal-100": {
      "color": "#ccfbf1"
    },
    "color-teal-200": {
      "color": "#99f6e4"
    },
    "color-teal-300": {
      "color": "#5eead4"
    },
    "color-teal-400": {
      "color": "#2dd4bf"
    },
    "color-teal-50": {
      "color": "#f0fdfa"
    },
    "color-teal-500": {
      "color": "#14b8a6"
    },
    "color-teal-600": {
      "color": "#0d9488"
    },
    "color-teal-700": {
      "color": "#0f766e"
    },
    "color-teal-800": {
      "color": "#115e59"
    },
    "color-teal-900": {
      "color": "#134e4a"
    },
    "color-teal-950": {
      "color": "#042f2e"
    },
    "color-transparent": {
      "color": "transparent"
    },
    "color-trueGray-100": {
      "color": "#f5f5f5"
    },
    "color-trueGray-200": {
      "color": "#e5e5e5"
    },
    "color-trueGray-300": {
      "color": "#d4d4d4"
    },
    "color-trueGray-400": {
      "color": "#a3a3a3"
    },
    "color-trueGray-50": {
      "color": "#fafafa"
    },
    "color-trueGray-500": {
      "color": "#737373"
    },
    "color-trueGray-600": {
      "color": "#525252"
    },
    "color-trueGray-700": {
      "color": "#404040"
    },
    "color-trueGray-800": {
      "color": "#262626"
    },
    "color-trueGray-900": {
      "color": "#171717"
    },
    "color-trueGray-950": {
      "color": "#0a0a0a"
    },
    "color-violet-100": {
      "color": "#ede9fe"
    },
    "color-violet-200": {
      "color": "#ddd6fe"
    },
    "color-violet-300": {
      "color": "#c4b5fd"
    },
    "color-violet-400": {
      "color": "#a78bfa"
    },
    "color-violet-50": {
      "color": "#f5f3ff"
    },
    "color-violet-500": {
      "color": "#8b5cf6"
    },
    "color-violet-600": {
      "color": "#7c3aed"
    },
    "color-violet-700": {
      "color": "#6d28d9"
    },
    "color-violet-800": {
      "color": "#5b21b6"
    },
    "color-violet-900": {
      "color": "#4c1d95"
    },
    "color-violet-950": {
      "color": "#2e1065"
    },
    "color-warmGray-100": {
      "color": "#f5f5f4"
    },
    "color-warmGray-200": {
      "color": "#e7e5e4"
    },
    "color-warmGray-300": {
      "color": "#d6d3d1"
    },
    "color-warmGray-400": {
      "color": "#a8a29e"
    },
    "color-warmGray-50": {
      "color": "#fafaf9"
    },
    "color-warmGray-500": {
      "color": "#78716c"
    },
    "color-warmGray-600": {
      "color": "#57534e"
    },
    "color-warmGray-700": {
      "color": "#44403c"
    },
    "color-warmGray-800": {
      "color": "#292524"
    },
    "color-warmGray-900": {
      "color": "#1c1917"
    },
    "color-warmGray-950": {
      "color": "#0c0a09"
    },
    "color-white": {
      "color": "#fff"
    },
    "color-yellow-100": {
      "color": "#fef9c3"
    },
    "color-yellow-200": {
      "color": "#fef08a"
    },
    "color-yellow-300": {
      "color": "#fde047"
    },
    "color-yellow-400": {
      "color": "#facc15"
    },
    "color-yellow-50": {
      "color": "#fefce8"
    },
    "color-yellow-500": {
      "color": "#eab308"
    },
    "color-yellow-600": {
      "color": "#ca8a04"
    },
    "color-yellow-700": {
      "color": "#a16207"
    },
    "color-yellow-800": {
      "color": "#854d0e"
    },
    "color-yellow-900": {
      "color": "#713f12"
    },
    "color-yellow-950": {
      "color": "#422006"
    },
    "color-zinc-100": {
      "color": "#f4f4f5"
    },
    "color-zinc-200": {
      "color": "#e4e4e7"
    },
    "color-zinc-300": {
      "color": "#d4d4d8"
    },
    "color-zinc-400": {
      "color": "#a1a1aa"
    },
    "color-zinc-50": {
      "color": "#fafafa"
    },
    "color-zinc-500": {
      "color": "#71717a"
    },
    "color-zinc-600": {
      "color": "#52525b"
    },
    "color-zinc-700": {
      "color": "#3f3f46"
    },
    "color-zinc-800": {
      "color": "#27272a"
    },
    "color-zinc-900": {
      "color": "#18181b"
    },
    "color-zinc-950": {
      "color": "#09090b"
    }
  },
  atRules: {
    "body": [[["media", "(prefers-color-scheme: dark)"]]],
    "container": [[["media", "(min-width: 640px)"]], [["media", "(min-width: 768px)"]], [["media", "(min-width: 1024px)"]], [["media", "(min-width: 1280px)"]], [["media", "(min-width: 1536px)"]]]
  },
  topics: {
    "container": ["width"]
  }
});
