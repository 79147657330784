@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    background-color: #ffffff;
  }
  @media (prefers-color-scheme: dark) {
    body {
      background-color: #000000;
    }
  }
}
